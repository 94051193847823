import { Suspense, lazy } from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import {
  USER_TYPE_MASTER,
  drmClientUrls,
  ORGANIZATION_STATUS_OPTIONS,
  maintenanceModeClientUrl,
  jsonCargo,
  authClientUrls,
} from "@lb/utils";
import {
  EmbeddedLayout,
  LbLogoLoading,
  useToken,
  useClient,
  usePermissions,
  useModules,
  useOrganization,
  lbUrlNavigator,
} from "@lb/frontend";

const EmbeddedDocuments = lazy(() => import("./pages/Documents"));

const EmbeddedDocumentUploadEdit = lazy(() => import("./pages/Documents/UploadEdit"));

const EmbeddedEditDocument = lazy(() => import("./pages/Documents/Edit"));

const Documents = lazy(() => {
  return usePermissions.getState().permissions?.drm?.documents &&
    useModules.getState().modules?.drm?.active
    ? import("./pages/Documents")
    : import("./pages/AccessDenied");
});

const DocumentUploadEdit = lazy(() =>
  usePermissions.getState().permissions?.drm?.documents?.create &&
  useModules.getState().modules?.drm?.documents?.upload
    ? import("./pages/Documents/UploadEdit")
    : import("./pages/AccessDenied")
);

const EditDocument = lazy(() =>
  usePermissions.getState().permissions?.drm?.documents?.edit &&
  useModules.getState().modules?.drm?.documents?.update
    ? import("./pages/Documents/Edit")
    : import("./pages/AccessDenied")
);

const Tags = lazy(() =>
  usePermissions.getState().permissions?.drm?.tags &&
  useModules.getState().modules?.drm?.tags?.create
    ? import("./pages/Tags")
    : import("./pages/AccessDenied")
);

const AddTags = lazy(() =>
  usePermissions.getState().permissions?.drm?.tags?.create &&
  useModules.getState().modules?.drm?.tags?.create
    ? import("./pages/Tags/AddEdit")
    : import("./pages/AccessDenied")
);

const EditTags = lazy(() =>
  usePermissions.getState().permissions?.drm?.tags?.update &&
  useModules.getState().modules?.drm?.tags?.update
    ? import("./pages/Tags/AddEdit")
    : import("./pages/AccessDenied")
);

const AppLayout = lazy(() => {
  if (useToken.getState().token) {
    if (useClient.getState()?.client?.userType === USER_TYPE_MASTER) {
      window.location.replace(process.env.REACT_APP_MASTER_URL);
    } else {
      if (
        useOrganization.getState()?.organization?.status ===
        ORGANIZATION_STATUS_OPTIONS.key.MAINTENANCE
      ) {
        window.location.replace(
          lbUrlNavigator(maintenanceModeClientUrl.base, "IAM") +
            `#${jsonCargo.store({ from: window.location.href })}`
        );
      }
      return import("@lb/frontend")?.then?.((module) => ({
        default: module.LbLayout,
      }));
    }
  } else {
    window.location.replace(
      lbUrlNavigator(authClientUrls.login.base, "AUTH") +
        `#${jsonCargo.store({ from: window.location.href })}`
    );
  }

  return new Promise(() => {});
});

const router = createBrowserRouter([
  {
    path: "/embedded",
    element: <EmbeddedLayout />,
    children: [
      {
        path: drmClientUrls.documents.slug,
        element: <EmbeddedDocuments />,
      },
      {
        path: drmClientUrls.documents.slug + "/" + drmClientUrls.documents.upload.slug,
        element: <EmbeddedDocumentUploadEdit />,
      },
      {
        path: drmClientUrls.documents.slug + "/" + drmClientUrls.documents.update.slug,
        element: <EmbeddedEditDocument />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <Suspense fallback={<LbLogoLoading />}>
        <AppLayout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: <Navigate to={drmClientUrls.documents.slug} replace />,
      },
      {
        path: drmClientUrls.documents.slug,
        children: [
          {
            path: "",
            element: <Documents />,
            children: [
              {
                path: drmClientUrls.documents.upload.slug,
                element: <DocumentUploadEdit />,
              },
            ],
          },
          {
            path: drmClientUrls.documents.update.slug,
            element: <EditDocument />,
          },
        ],
      },
      {
        path: drmClientUrls.tags.slug,
        element: <Tags />,
        children: [
          {
            path: drmClientUrls.tags.create.slug,
            element: <AddTags />,
          },
          {
            path: drmClientUrls.tags.update.slug,
            element: <EditTags />,
          },
        ],
      },
    ],
  },
]);

export default router;
